import { useEffect } from 'react';
import { connect } from 'react-redux';
import Types from '@taktik/common/types';
import Reducers from '@taktik/common/reducers';
import GroupIcon from '@mui/icons-material/Group';
import DomainIcon from '@mui/icons-material/Domain';

import Can from '@taktik/components/Can';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import Explore from '@mui/icons-material/Explore';
import Translate from '@mui/icons-material/Translate';

import useStyles from '../../../../styles';
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, batch } from 'react-redux';
import T from '@taktik/components/T';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import i18next from 'i18next';

interface MainListItemsProps {
	classrooms: Types.ClassroomDocument[];
	user: Types.User;
	token: string;
}

const MainListItems = (props: MainListItemsProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const classes = useStyles();
	const dispatch = useDispatch();
	const language = i18next.language as Types.Languages;

	// @pusher
	const channel = useChannel('schools');
	useEvent(channel, 'refresh', (data: Types.PusherSchoolRefresh | undefined) => {
		if (data && data.schoolId) {
			dispatch(Reducers.common.schools.actions.get(props.token, {}, { page: 0, pageSize: 50 }));
			dispatch(Reducers.common.classrooms.actions.get(props.token, data.schoolId));
		}
	});

	useEffect(() => {
		if (props.user) {
			batch(() => {
				dispatch(Reducers.common.subjects.actions.get(props.token));
				dispatch(Reducers.common.users.actions.get(props.token, {}, { page: 0, pageSize: 50 }));
				dispatch(Reducers.voti.devices.actions.get(props.token));
				dispatch(Reducers.voti.questionTypes.actions.get(props.token));
				dispatch(Reducers.voti.segments.actions.get(props.token));
				dispatch(Reducers.voti.quotes.actions.get(props.token));
			});
		}
	}, [props.token, dispatch, props.user]);

	return (
		<List>
			{/* <ListItem
						button
						onClick={() => navigate('/')}
						selected={location.pathname === '/'}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.dashboard" />} />
					</ListItem> */}
			<Can
				project="central"
				language={language}
				perform="admin:users"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/users')}
						selected={location.pathname === '/users'}
						id="nav-admin-users"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<GroupIcon />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.users" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="central"
				language={language}
				perform="admin:schools"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/schools')}
						selected={location.pathname.startsWith('/schools')}
						id="nav-admin-schools"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<DomainIcon />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.schools" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="central"
				language={language}
				perform="admin:subjects"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/subjects')}
						selected={location.pathname === '/subjects'}
						id="nav-admin-subjects"
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Explore />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.subjects" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="central"
				language={language}
				perform="admin:emailTemplate"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/emails')}
						selected={location.pathname.startsWith('/emails')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<AlternateEmail />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.emailTemplate" />} />
					</ListItem>
				)}
				no={() => null}
			/>
			<Can
				project="central"
				language={language}
				perform="admin:translations"
				yes={() => (
					<ListItem
						button
						onClick={() => navigate('/translations')}
						selected={location.pathname.startsWith('/translations')}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<Translate />
						</ListItemIcon>
						<ListItemText primary={<T id="navBar.translations" />} />
					</ListItem>
				)}
				no={() => null}
			/>
		</List>
	);
};

export default connect((state: Types.RootState) => ({
	classrooms: state.classrooms,
	user: state.user,
}))(MainListItems);
