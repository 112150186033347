import { combineReducers } from 'redux';
import Reducers from '@taktik/common/reducers';

const rootReducer = combineReducers({
	books: Reducers.interaktiv.books.reducer,
	user: Reducers.common.user.reducer,
	users: Reducers.common.users.reducer,
	schools: Reducers.common.schools.reducer,
	classrooms: Reducers.common.classrooms.reducer,
	subjects: Reducers.common.subjects.reducer,
	invites: Reducers.common.invites.reducer,
	snackbars: Reducers.common.snackbars.reducer,
	translations: Reducers.common.translations.reducer,
	translationEdit: Reducers.common.translationEdit.reducer,
	notifications: Reducers.common.notifications.reducer,
	emailTemplates: Reducers.common.emailTemplates.reducer,
	devices: Reducers.voti.devices.reducer,
});

export default rootReducer;
