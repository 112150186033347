import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import T from '@taktik/components/T';
import useStyles from '../../../../styles';
import changelogFile from '../../../../CHANGELOG.md';
import ReactMarkdown from 'react-markdown';

interface AppVersionProps {
	open: boolean;
	onClose: () => void;
}

const AppVersion = (props: AppVersionProps) => {
	const open = props.open;
	const onClose = props.onClose;
	const [changelog, setChangelog] = useState('');
	const classes = useStyles();

	useEffect(() => {
		fetch(changelogFile)
			.then(res => res.text())
			.then(text => setChangelog(text));
	});

	return (
		<Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="xs">
			<DialogTitle>
				<T id="dialogs.changelog.title" />
			</DialogTitle>
			<DialogContent>
				<ReactMarkdown>{changelog}</ReactMarkdown>
			</DialogContent>
			<DialogActions className={classes.dialogActionsSingle}>
				<Button onClick={() => onClose()} fullWidth>
					<T id="dialogs.changelog.close" />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AppVersion;
