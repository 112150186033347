import { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider, useDispatch } from 'react-redux';
import reducer from './reducers';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { PusherProvider } from '@harelpls/use-pusher';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import './assets/fonts.css';
import moment from 'moment';
import 'moment/locale/cs';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import theme from './theme';
import NavBar from './components/common/NavBar';

// Intro.js
import 'intro.js/introjs.css';

import TranslationLoader from '@taktik/components/TranslationLoader';
import ErrorBoundary from '@taktik/components/ErrorBoundary';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import Loader from '@taktik/components/Loader';
import Auth, { authContext } from '@taktik/common/auth';
import axios from 'axios';
// timeago.register('cs', cs);

// Unauthorized
const HomePage = lazy(() => import('./pages/home'));

// Admin
const DashboardPage = lazy(() => import('./pages/admin'));
const ProfilePage = lazy(() => import('./pages/admin/profile'));
const UsersPage = lazy(() => import('./pages/admin/users'));
const SchoolsPage = lazy(() => import('./pages/admin/schools'));
const SubjectsPage = lazy(() => import('./pages/admin/subjects'));
const EmailsPage = lazy(() => import('./pages/admin/emails'));
const TranslationsPage = lazy(() => import('./pages/admin/translations'));
const ResetPasswordPage = lazy(() => import('./pages/resetPassword'));

// Moment locale
moment.updateLocale('cs', {
	week: {
		dow: 1,
		doy: 1,
	},
});

// Pusher config
const pusherConfig = {
	clientKey: '0d7a53bab3e525777c45',
	cluster: 'eu',
};

i18next.init({
	interpolation: { escapeValue: false },
	lng: 'cs',
	resources: {
		cs: {
			common: {},
			quiz: {},
		},
	},
});

const store = createStore(reducer, applyMiddleware(thunk));

axios.defaults.params = {};
axios.defaults.params['project'] = 'central';
axios.defaults.params['language'] = i18next.language;

const App = () => {
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	store.subscribe(() => {
		const state = store.getState();
		const snackbars: Types.Snackbar[] = state.snackbars;
		snackbars.forEach(snackbar => {
			if (!snackbar.shown) {
				enqueueSnackbar(snackbar.message, {
					variant: snackbar.type,
				});
				dispatch(Reducers.common.snackbars.actions.clear());
			}
		});
	});

	return (
		<ThemeProvider theme={theme}>
			<StyledEngineProvider injectFirst>
				<Auth>
					<authContext.Consumer>
						{({ authenticated, token, silentAuth }) => {
							if (!authenticated && token) {
								silentAuth();
								return;
							}

							return loading ? (
								<>
									<TranslationLoader
										language={i18next.language as Types.Languages}
										onLoad={() => setLoading(false)}
									/>
									<Loader />
								</>
							) : (
								<Router>
									{/* <Guide /> */}
									{/* <TranslationEdit /> */}
									{/* <ScrollRestoration /> */}
									<Routes>
										{/* Public routes */}
										<Route
											path="/"
											element={
												<Suspense fallback={<Loader />}>
													<HomePage />
												</Suspense>
											}
										/>

										{/* Admin routes */}
										<Route
											path="/admin"
											element={
												<NavBar>
													<Suspense fallback={<Loader />}>
														<DashboardPage />
													</Suspense>
												</NavBar>
											}
										/>
										<Route
											path="/profile"
											element={
												<NavBar>
													<Suspense fallback={<Loader />}>
														<ProfilePage />
													</Suspense>
												</NavBar>
											}
										/>
										<Route
											path="/translations"
											element={
												<NavBar>
													<Suspense fallback={<Loader />}>
														<TranslationsPage />
													</Suspense>
												</NavBar>
											}
										/>
										{['/users/:page/:userId', '/users/:page', '/users'].map(path => (
											<Route
												path={path}
												key="router"
												element={
													<NavBar>
														<Suspense fallback={<Loader />}>
															<UsersPage />
														</Suspense>
													</NavBar>
												}
											/>
										))}
										{['/schools/:page/:schoolId/:tabId', '/schools/:page/:schoolId', '/schools'].map(path => (
											<Route
												path={path}
												key="router"
												element={
													<NavBar>
														<Suspense fallback={<Loader />}>
															<SchoolsPage />
														</Suspense>
													</NavBar>
												}
											/>
										))}
										<Route
											path="/subjects"
											element={
												<NavBar>
													<Suspense fallback={<Loader />}>
														<SubjectsPage />
													</Suspense>
												</NavBar>
											}
										/>
										{['/emails', '/email/:emailId'].map(path => (
											<Route
												path={path}
												key="router"
												element={
													<NavBar>
														<Suspense fallback={<Loader />}>
															<EmailsPage />
														</Suspense>
													</NavBar>
												}
											/>
										))}
										<Route
											path="/reset-password/:userId/:hash"
											element={
												<Suspense fallback={<Loader />}>
													<ResetPasswordPage />
												</Suspense>
											}
										/>
									</Routes>
								</Router>
							);
						}}
					</authContext.Consumer>
				</Auth>
			</StyledEngineProvider>
		</ThemeProvider>
	);
};

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	root.render(
		<Provider store={store}>
			<SnackbarProvider
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				maxSnack={3}
			>
				<I18nextProvider i18n={i18next}>
					<PusherProvider {...pusherConfig}>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<ErrorBoundary>
								<App />
							</ErrorBoundary>
						</LocalizationProvider>
					</PusherProvider>
				</I18nextProvider>
			</SnackbarProvider>
		</Provider>
	);
}
