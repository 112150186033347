import { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import Types from '@taktik/common/types';
import UserMenu from './components/UserMenu';
import ChangelogDialog from '../../admin/dialog/Changelog';
import useStyles from '../../../styles';
import ListItemsAdmin from './components/ListItemsAdmin';
import Notifications from './components/Notifications';
import Image from '../Image';
import logo from '../../../assets/taktik-logo.svg';
import theme from '../../../theme';

interface NavBarProps {
	children: React.ReactElement;
	user: Types.UserSession;
	users: Types.User[];
	classrooms: Types.ClassroomDocument[];
	page?: string;
	socket: Types.VotiSocket;
}

const NavBar = (props: NavBarProps) => {
	const navigate = useNavigate();
	const classes = useStyles();
	const [user, setUser] = useState<Types.User>(props.user);
	const [showAppVersion, setShowAppVersion] = useState(false);
	const { pathname } = useLocation();
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		contentRef.current?.scrollTo(0, 0);
	}, [pathname]);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="absolute"
				style={{
					zIndex: theme.zIndex.drawer + 1,
					transition: theme.transitions.create(['width', 'margin'], {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					boxShadow:
						'0px 2px 20px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 1%)',
				}}
				color="default"
			>
				<Toolbar style={{ minHeight: 52 }}>
					<div className={classes.logo} onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
						<Grid container spacing={1} alignItems="center">
							<Grid item>
								<Image
									path={logo}
									isPublic
									style={{
										height: 34,
									}}
								/>
							</Grid>
							<Grid item>
								<Typography variant="h6" style={{ paddingBottom: 6 }}>
									{`Centrála`}
								</Typography>
							</Grid>
						</Grid>
					</div>
					<Notifications token={props.user.token} />
					<UserMenu user={user} />
				</Toolbar>
			</AppBar>
			<Paper className={classes.drawerPaper} hidden={user.role === Types.RoleTypesList.customer}>
				{(user.role === Types.RoleTypesList.superadmin ||
					user.role === Types.RoleTypesList.admin ||
					user.role === Types.RoleTypesList.employee) && <ListItemsAdmin token={props.user.token} />}
				<div className={classes.versionControl}>
					<Link
						style={{
							color: theme.palette.text.primary,
							cursor: 'pointer',
						}}
						onClick={() => setShowAppVersion(true)}
					>
						{process.env.REACT_APP_VERSION}
					</Link>
				</div>
			</Paper>
			<main className={classes.contentOpen} ref={contentRef}>
				<div className={classes.appBarSpacer} />
				<Container maxWidth="xl" className={classes.container}>
					{props.children}
				</Container>
				<Box mb={40}></Box>
			</main>
			{showAppVersion && <ChangelogDialog open onClose={() => setShowAppVersion(false)} />}
		</div>
	);
};

export default connect((state: Types.RootState) => ({
	classrooms: state.classrooms,
	users: state.users,
	socket: state.socket,
	user: state.user,
}))(NavBar);
