import { useEffect, useState } from 'react';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router';
import Reducers from '@taktik/common/reducers';
import Types from '@taktik/common/types';
import { connect, useDispatch } from 'react-redux';
import { useChannel, useEvent } from '@harelpls/use-pusher';
import T from '@taktik/components/T';

interface NotificationsProps {
	notifications: Types.NotificationDocument[];
	user: Types.User;
	token: string;
}

const Notifications = (props: NotificationsProps) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const newNotifications = props.notifications.filter(n => n.state === 'new');
	const lastNotifications = props.notifications.slice(0, 10);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		if (newNotifications.length > 0) {
			dispatch(
				Reducers.common.notifications.actions.seen(
					newNotifications.map(n => n._id),
					props.token
				)
			);
		}
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	// @pusher
	const channel = useChannel('notifications');
	useEvent(channel, 'refresh', (data: Types.PusherNotificationsRefresh | undefined) => {
		if (data && data.users && data.users.findIndex(u => u === props.user._id) > -1) {
			dispatch(Reducers.common.notifications.actions.get(props.token));
		}
	});

	useEffect(() => {
		dispatch(Reducers.common.notifications.actions.get(props.token));
	}, [dispatch, props.token]);

	return (
		<>
			<IconButton color="inherit" onClick={handleClick}>
				<Badge badgeContent={newNotifications.length} color="secondary">
					<NotificationsIcon color={newNotifications.length > 0 ? 'action' : 'disabled'} />
				</Badge>
			</IconButton>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{lastNotifications.length > 0 && (
					<List>
						{lastNotifications.map(notification => (
							<ListItem
								button
								key={notification._id}
								// onClick={() => {
								// 	const source = notification.source;
								// 	const target = notification.target;
								// 	const data = target.data;
								// 	const role = props.user.role;
								// 	const uriPrefix =
								// 		role === Types.RoleTypesList.teacher
								// 			? '/t'
								// 			: role === Types.RoleTypesList.student
								// 			? '/s'
								// 			: '/admin';
								// 	switch (notification.target.notificationType) {
								// 		case 'repeatingNew':
								// 			navigate(`${uriPrefix}/repeating/${data.repeatingId}/${data.itemId}`);
								// 			break;
								// 		case 'repeatingResult':
								// 			if (source.users.length === 1) {
								// 				navigate(
								// 					`${uriPrefix}/repeating/${data.repeatingId}/${data.itemId}/${source.users[0]}`
								// 				);
								// 			} else {
								// 				navigate(`${uriPrefix}/repeating/${data.repeatingId}/${data.itemId}`);
								// 			}
								// 			break;
								// 		case 'inviteNew':
								// 			navigate(`${uriPrefix}/invite/${data.inviteId}`);
								// 			break;
								// 		case 'inviteResult':
								// 			if (role === Types.RoleTypesList.student) {
								// 				navigate(`${uriPrefix}/schools/edit/${data.schoolId}/${data.classroomId}`);
								// 			} else {
								// 				navigate(`${uriPrefix}/classroom/${data.classroomId}`);
								// 			}
								// 			break;
								// 	}
								// 	setAnchorEl(null);
								// 	dispatch(Reducers.common.notifications.actions.seen([notification._id], props.token));
								// }}
								selected={notification.state === 'new'}
							>
								<ListItemText primary={notification.title} secondary={notification.description} />
							</ListItem>
						))}
					</List>
				)}
				{lastNotifications.length === 0 && (
					<Box p={3}>
						<Typography>
							<T id="navBar.notifications.noResults" />
						</Typography>
					</Box>
				)}
			</Popover>
		</>
	);
};

export default connect((state: Types.RootState) => ({
	notifications: state.notifications,
	user: state.user,
}))(Notifications);
